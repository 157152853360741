const LoginButton = () => {
  var authURL = process.env.REACT_APP_AUTH0_AUTH_URL!;

  if (process.env.REACT_APP_AUTH0_REDIRECT_URL) {
    authURL = authURL + "?domain=" + process.env.REACT_APP_AUTH0_REDIRECT_URL
  }

  const Authenticate = async () => {
    fetch(authURL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        console.log(response)
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.redirectUrl && data.redirectUrl !== "") {
          var redirectURL = data.redirectUrl;
          window.location.replace(redirectURL);
        }
      });
  };

  return (
    <button
      className="btn btn-primary btn-block"
      onClick={() => Authenticate()}
    >
      Log In
    </button>
  );
};

export default LoginButton;
