import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import md5 from "md5";
import LogoutButton from "../../components/LogoutButton";

const Sidebar = () => {
  const user = useContext(UserContext);
  const avatarURL =
    "https://www.gravatar.com/avatar/" + md5(user?.email) + ".jpg";

  return (
    <>
      {/* <!-- Main Sidebar Container --> */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4 flex-col">
        {/* <!-- Brand Logo --> */}
        <a href="/" className="brand-link">
          <span className="brand-text font-weight-light">
            React Basic Template
          </span>
        </a>

        {/* <!-- Sidebar --> */}
        <div className="sidebar flex-col">
          {/* <!-- Sidebar user panel (optional) --> */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={avatarURL}
                className="img-circle elevation-2"
                alt="User"
              />
            </div>
            <div className="info">
              <span className="welcomeuser">Hello,</span>
              <a href="/" className="d-block">
                {user.name}
              </a>
            </div>
          </div>

          {/* <!-- Sidebar Menu --> */}
          <nav className="mt-2 flex-col">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
            >
              {/* <!-- Add icons to the links using the .nav-icon className */}
              {/* with font-awesome or any other icon font library --> */}
              <li className="nav-item menu-open">
                <a href="/" className="nav-link active">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    Starter Pages
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="/" className="nav-link active">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Active Page</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/" className="nav-link">
                      <i className="far fa-circle nav-icon"></i>
                      <p>Inactive Page</p>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="nav-icon fas fa-th"></i>
                  <p>
                    Simple Link
                    <span className="right badge badge-danger">New</span>
                  </p>
                </a>
              </li>
              <li className="nav-item end mb-2">
                <LogoutButton />
              </li>
            </ul>
          </nav>
          {/* <!-- /.sidebar-menu --> */}
        </div>
        {/* <!-- /.sidebar --> */}
      </aside>
    </>
  );
};

export default Sidebar;
