const LogoutButton = () => {

  const Logout = () => {
    localStorage.removeItem("aT");
    window.location.replace("/login");
  }

  return <button className="btn btn-danger btn-block" onClick={() => Logout()}>Log Out</button>;
};

export default LogoutButton;
