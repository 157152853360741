import { Navigate, Outlet } from 'react-router-dom';


const PrivateRoute = () => {
    const token = localStorage.getItem("aT");
    const isLoggedIn = token ? true : false;

    // TODO: use auth0 login state OR redux ?
    return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;