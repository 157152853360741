import React from "react";
import AuthenticateLogin from "../../components/AuthenticateLogin";
import LoginButton from "../../components/LoginButton";

const Login = () => {
  return (
    <>
      {<AuthenticateLogin />}
      <div className="fullpage centercontent">
        <div className="content">
          <div className="card small">
            <div className="card-header">
              <h3 className="card-title">Basic Login App</h3>
              {/* <div className="card-tools">
                <span className="badge badge-primary">{"<3"}</span>
              </div> */}
            </div>
            <div className="card-body">
              <LoginButton />
            </div>
            <div className="card-footer">LETS GOOOO</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
