import { Navigate, Outlet } from 'react-router-dom';

const PublicRoute = () => {
    const token = localStorage.getItem("aT");
    const isLoggedIn = token ? true : false;

    // check with oauth
    // const isAuthenticated = isLoggedIn || localStorage.getItem('token');

    return isLoggedIn ? <Navigate to="/" /> : <Outlet />;
}

export default PublicRoute;