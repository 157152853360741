import React, { createContext, useState, useEffect } from "react";
import LoadingOverlay from "../components/LoadingOverlay";

// defines the user object we store in our context
interface IUserContext {
  name: string;
  email: string;
}
// default State of our UserContext
const defaultState: IUserContext = {
  name: "unknown",
  email: "unknown",
};

// create context
const UserContext = createContext<IUserContext>(defaultState);
const AT = localStorage.getItem("aT");
const userAPI = process.env.REACT_APP_AUTH0_USERINFO_URL!;
const userURL = userAPI + "?token=" + localStorage.getItem("aT");

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  // the value that will be given to the context
  const [user, setUser] = useState<IUserContext>(defaultState);
  const [fetchedData, setFetchedData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // fetch a user from a fake backend API
  useEffect(() => {
    const fetchUser = () => {
      console.log("at", AT);
      // fetch code
      if (AT) {
        setIsLoading(true);
        fetch(userURL, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data) {
              setUser({ name: data.name, email: data.email });
              setIsLoading(false);
              setFetchedData(true);
            }
          });
      }
    };

    !fetchedData && fetchUser();
  });

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={user}>
      <LoadingOverlay show={isLoading}>{children}</LoadingOverlay>
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
