import React from "react";
import Footer from "../modules/footer/Footer";
import Header from "../modules/header/Header";
import Sidebar from "../modules/sidebar/Sidebar";

const Layout = ({
  children,
  isPrivate = true,
}: {
  children: React.ReactNode;
  isPrivate?: boolean;
}) => {
  return isPrivate ? (
    <>
      <Header />
      <Sidebar />
      <div className="content-wrapper">{children}</div>
      <Footer />
    </>
  ) : (
    <>
      <div className="content-wrapper">{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
