import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const AuthenticateLogin = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const loginAPI = process.env.REACT_APP_AUTH0_LOGIN_URL!;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getToken = () => {
      var code = queryParams.get("code");

      if (code) {
        var loginURL = loginAPI + "?code=" + code;

        if (process.env.REACT_APP_AUTH0_REDIRECT_URL) {
          loginURL =
            loginURL + "&domain=" + process.env.REACT_APP_AUTH0_REDIRECT_URL;
        }

        fetch(loginURL, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => {
            setIsLoading(true);
            return response.json();
          })
          .then((data) => {
            localStorage.setItem("aT", data.accessToken);
            navigate("/");
          });
      }
    };
    !isLoading && getToken();
  });

  return <></>;
};

export default AuthenticateLogin;
