import React from "react";

const LoadingOverlay = ({
  children,
  show,
}: {
  children: React.ReactNode;
  show: boolean;
}) => {
  return (
    <>
      {show ? (
        <div className="overlay-wrapper">
          <div className="overlay dark">
            <div id="spinner" />
            <span className="spinner-label">Loading..</span>
          </div>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LoadingOverlay;
